















import {Component, Prop, Vue} from "vue-property-decorator";
import ContentThin from "@/components/elements/ContentThin";
@Component({
             components: {ContentThin}
           })
export default class ContentSection extends Vue {
  @Prop({default: ''}) protected sectionNr!: string;
  @Prop({default: ''}) protected sectionLabel!: string;

  get isMobile() : boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get hasSectionMark() : boolean {
    return !!this.sectionLabel
  }
  get hasTopTitleSlot () {
    return !!this.$slots['content-top--title']
  }
  get hasTopContentSlot () {
    return !!this.$slots['content-top']
  }
}
